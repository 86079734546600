import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { MutationTree, ActionTree } from 'vuex'

declare module 'vuex/types/index' {
  interface Store<S> {
    $api: NuxtAxiosInstance
    $auth: NuxtAxiosInstance
  }
}

declare global {
  interface Window {
    appCues: any
    dataLayer: any
    wootricSettings: any
  }
}

interface LegalStructures {
  [legalStructureName: string]: {
    [id: number | string]: { agreement: string; isFiscalReceiptEligible: boolean }
  }
}

interface TaxInformationTexts {
  [index: number]: TaxInformationText
}

interface TaxInformationText {
  isColucheLawEnable: boolean
  isIfiEnable: boolean
  individualText: string
  companyText: string
}

interface OrganizationLegalInformation {
  legalStructure: string | null
  legalStructureId: number | string | null
  isFiscalReceiptEligible: boolean | null
  isColucheLawEnable: boolean
  isIfiEnable: boolean
  isHelloAssoFiscalReceiptTransmitter: boolean
}

interface SendingSettings {
  organizationType: string | null
  organizationActivityField: string | null
  organizationObject: string | null
  organizationHeadOfficeAddress: string | null
  organizationHeadOfficeZipCode: string | null
  organizationHeadOfficeCity: string | null
  signingMemberFirstName: string | null
  signingMemberLastName: string | null
  signingMemberFunction: string | null
  customizeFiscalReceiptsNumbering: boolean
  signingMemberSignatureUrl: string | null
}

// ! Redundancy
interface FormatOptionModel {
  date: string
  order: string
  root: string
  separator: string
}
interface SendingSettingsV2 {
  formatOptionModel: FormatOptionModel
  signatoryModel: {
    firstName: string
    lastName: string
    function: string
    fileUrl: string
  }
  addressModel: {
    line: string
    city: string
    zipCode: string
  }
  purpose: string
  cerfaTypeId: string | number
}

class State {
  legalStructures: any = []
  taxInformationTexts: TaxInformationTexts = []
  organizationLegalInformation: OrganizationLegalInformation | null = null
  organizationCategories: any[] | null = null
  activityFields: any[] | null = null
  sendingSettings: SendingSettingsV2 | null = null
  signatureFile: File | null = null
}

export const state = () => new State()

export const getters = {
  hasLoadedOrganizationsLegalStructures: (state) => () => state.legalStructures.length !== 0,
  hasLoadedTaxInformationTexts: (state) => () => state.taxInformationTexts.length !== 0,
  orderedLegalStructures: (state: State): LegalStructures => {
    return (
      state.legalStructures?.reduce((acc, legalStructureData: any) => {
        const { id, legalStructure, agreement, isFiscalReceiptEligible } = legalStructureData

        if (acc[legalStructure]) acc[legalStructure][id] = { agreement, isFiscalReceiptEligible }
        else acc[legalStructure] = { [id]: { agreement, isFiscalReceiptEligible } }

        return acc
      }, {}) ?? {}
    )
  },
  isFiscalReceiptEligible(state, getters): Boolean {
    if (!state.organizationLegalInformation) return false

    const { legalStructureId } = state.organizationLegalInformation
    return !!getters.selectedLegalStructure[legalStructureId]?.isFiscalReceiptEligible
  },

  organizationAgreements(
    state,
    getters
  ): (legalStructure?: string) => { value: number; text: string }[] {
    return (legalStructure?: string) => {
      const structure = legalStructure
        ? getters.orderedLegalStructures[legalStructure]
        : getters.selectedLegalStructure

      return (Object.entries(structure) as any)
        .map(([id, { agreement }]) => ({
          value: id,
          text: agreement ?? ''
        }))
        .sort((a, b) => a.text.localeCompare(b.text))
    }
  },

  organizationTypes(state, getters) {
    return Object.keys(getters.orderedLegalStructures)
      .map((legalStructureName) => ({
        value: legalStructureName,
        text: legalStructureName
      }))
      .sort((a, b) => a.text.localeCompare(b.text))
  },

  selectedLegalStructure(state, getters) {
    return getters.orderedLegalStructures[state.organizationLegalInformation.legalStructure] ?? []
  }
}

export const mutations = <MutationTree<State>>{
  SET_ORGANIZATIONS_LEGAL_STRUCTURES(state, { structures }: { structures: LegalStructures }) {
    state.legalStructures = structures
  },
  SET_TAX_INFORMATION_TEXTS(state, { texts }: { texts: TaxInformationTexts }) {
    state.taxInformationTexts = texts
  },
  SET_ORGANIZATION_LEGAL_INFORMATION(
    state,
    organizationLegalInformation: OrganizationLegalInformation
  ) {
    state.organizationLegalInformation = organizationLegalInformation
  },
  SET_SENDING_SETTINGS(state, sendingSettings: SendingSettingsV2) {
    state.sendingSettings = sendingSettings
  },

  SET_ORGANIZATION_CATEGORIES(state, organizationCategories) {
    state.organizationCategories = organizationCategories
  },
  SET_ACTIVITY_FIELDS(state, activityFields) {
    state.activityFields = activityFields
  },
  UPDATE_FORMAT_OPTION_MODEL(state, formatOptions: FormatOptionModel) {
    state.sendingSettings!.formatOptionModel = {
      ...state.sendingSettings?.formatOptionModel,
      ...formatOptions
    }
  },
  UPDATE_HA_MANDATE(state, value: boolean) {
    if (!state.organizationLegalInformation) {
      throw new Error('organizationLegalInformation not yet collected')
    }

    state.organizationLegalInformation.isHelloAssoFiscalReceiptTransmitter = value
  },
  UPDATE_SIGNATURE_FILE(state, file: File) {
    state.signatureFile = file
  }
}

export const actions = <ActionTree<State, any>>{
  updateSignatureFile({ commit }, file: File) {
    return commit('UPDATE_SIGNATURE_FILE', file)
  },
  updateHaMandate({ commit }, value: boolean) {
    return commit('UPDATE_HA_MANDATE', value)
  },

  updateFormatOptionModel({ commit }, updatedProperty: Partial<FormatOptionModel>) {
    return commit('UPDATE_FORMAT_OPTION_MODEL', updatedProperty)
  },
  async fetchOrganizationCategories({ state, commit }) {
    if (state.organizationCategories?.length) return Promise.resolve()

    const { data } = await this.$api.get('values/organization/categories')
    commit('SET_ORGANIZATION_CATEGORIES', data)

    return data
  },

  async fetchActivityFields({ state, commit }) {
    if (state.activityFields?.length) return Promise.resolve()

    const { data } = await this.$api.get('values/legal-structures')
    commit('SET_ACTIVITY_FIELDS', data)

    return data
  },

  async saveSendingSetting(
    { state, commit },
    {
      organizationSlug,
      config,
      file
    }: { organizationSlug: string; config: SendingSettingsV2; file?: File }
  ) {
    const formData = new FormData()
    formData.append('config', JSON.stringify(config))
    if (file) formData.append('file', file)
    // Object.entries(values).forEach(([key, val]) => formData.append(key, val))

    return this.$api.put(
      `organizations/legal-informations/${organizationSlug}/tax-receipt/configuration`,
      formData
    )
  },

  async saveFiscalReceiptOrganizationConfiguration(
    { state, commit },
    { organizationSlug, isHelloassoTransmitter }
  ) {
    const { legalStructureId, isColucheLawEnable, isIfiEnable } =
      state.organizationLegalInformation!

    const apiFormatConfiguration = {
      isHelloassoTransmitter,
      legalStructureId: Number(legalStructureId),
      isColuche: isColucheLawEnable,
      allowIfiTaxReductions: isIfiEnable
    }

    try {
      this.$api.put(
        `organizations/legal-informations/${organizationSlug}/configuration`,
        apiFormatConfiguration
      )

      // There is no response from put request
      commit('SET_ORGANIZATION_LEGAL_INFORMATION', {
        ...state.organizationLegalInformation,
        isHelloAssoFiscalReceiptTransmitter: isHelloassoTransmitter
      })
    } catch (error) {
      throw error
    }
  },

  async fetchOrganizationsLegalStructures({ commit, getters }) {
    if (getters.hasLoadedOrganizationsLegalStructures()) {
      return Promise.resolve()
    }

    try {
      const { data: structures } = await this.$api.get(
        '/organizations/legal-informations/legal-structures'
      )
      commit('SET_ORGANIZATIONS_LEGAL_STRUCTURES', { structures })
    } catch (error) {
      throw error
    }
  },

  async fetchTaxInformationTexts(
    { commit, getters },
    { organizationSlug }: { organizationSlug: string }
  ) {
    if (getters.hasLoadedTaxInformationTexts()) {
      return Promise.resolve()
    }

    const { data: texts } = await this.$api.get(
      '/organizations/legal-informations/tax-information-texts',
      {
        params: { organizationSlug }
      }
    )
    commit('SET_TAX_INFORMATION_TEXTS', { texts })
  },

  // Fired on form's field update
  syncComponentFormWithStore({ state, commit, getters }, form: OrganizationLegalInformation) {
    commit('SET_ORGANIZATION_LEGAL_INFORMATION', { ...form })
  },

  saveSendingSettingsForm({ commit, getters }, form: SendingSettings) {
    commit('SET_SENDING_SETTINGS', { ...form })
  },
  async loadOrganizationLegalInformation(
    { commit },
    { organizationSlug }: { organizationSlug: string }
  ) {
    const { data: organizationInfo } = await this.$api.get(
      `/organizations/legal-informations/${organizationSlug}/configuration`,
      {
        params: { organizationSlug }
      }
    )
    const { data } = await this.$api.get('/organizations/legal-informations/legal-structures')

    const organizationLegalStructure = data.find(
      ({ id }) => id === organizationInfo.legalStructureId
    )

    commit('SET_ORGANIZATION_LEGAL_INFORMATION', {
      legalStructureId: organizationInfo.legalStructureId,
      legalStructure: organizationLegalStructure.legalStructure,
      agreement: organizationLegalStructure.agreement,
      isFiscalReceiptEligible: organizationLegalStructure.isFiscalReceiptEligible,
      isColucheLawEnable: organizationInfo.isColucheLawEnabled,
      isIfiEnable: organizationInfo.isIfiEnabled,
      isHelloAssoFiscalReceiptTransmitter: organizationInfo.isHelloAssoFiscalReceiptTransmitter
    })
  },

  async loadSendingSettings({ commit }, { organizationSlug }: { organizationSlug: string }) {
    const { data } = await this.$api.get(
      `/organizations/legal-informations/${organizationSlug}/tax-receipt/configuration`
    )
    commit('SET_SENDING_SETTINGS', data)
  },

  resetSendingSettings({ dispatch }, { organizationSlug }: { organizationSlug: string }) {
    dispatch('loadSendingSettings', { organizationSlug })
  }
}
